import React from "react";
import Plans from "../../components/Plans/Plans";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";

const PlansPage = () => {
  return (
    <>
      <Navbar />
      <Plans />
      <Footer />
    </>
  );
};

export default PlansPage;
