import * as React from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import ImgMediaCard from "../MediaCard/ImgMediaCard";
import CardContent from "@mui/material/CardContent";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function ResponsiveGrid({ data }) {
  console.log("data: ", data);
  return (
    <Box sx={{ flexGrow: 1, pt: 15 }}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {data &&
          data.map((el, index) => (
            <Grid xs={2} sm={4} md={4} key={index}>
              <ImgMediaCard elem={el}>
                <p>{index + 1}</p>
              </ImgMediaCard>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}
