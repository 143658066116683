// ScannerImageGenerator.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const Input = styled.input`
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 200px;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const ImageContainer = styled.div`
  margin: 20px 0;
`;

const DownloadLink = styled.a`
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  margin: 10px;
  cursor: pointer;

  &:hover {
    background-color: #218838;
  }
`;

const ScannerMaker = () => {
  const [referrerId, setReferrerId] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const navigate = useNavigate();

  const generateScannerImage = () => {
    const imageUrl = `http://getinshape.co.in/clientform/${referrerId}`;
    const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?data=${imageUrl}&size=200x200`;
    setImageSrc(qrCodeUrl);
  };

  const goToFormPage = () => {
    navigate(`/clientform/${referrerId}`);
  };

  return (
    <Container>
      <Input
        type="text"
        id="referrerId"
        value={referrerId}
        onChange={(e) => setReferrerId(e.target.value)}
        placeholder="Enter Referrer ID"
      />
      <Button onClick={generateScannerImage}>Generate Scanner Image</Button>
      <ImageContainer>
        {imageSrc && <img src={imageSrc} alt="Scanner" />}
      </ImageContainer>
      <DownloadLink id="downloadLink" href={imageSrc} download="scanner.png">
        Download Image
      </DownloadLink>
      <Button onClick={goToFormPage}>Go to Form</Button>
    </Container>
  );
};

export default ScannerMaker;
