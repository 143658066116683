import { Button, FormControl, FormLabel, TextField } from "@mui/material";
import React, { useState } from "react";
import axios from "axios";
import styles from "./clientForm.module.css";
import { useParams } from "react-router-dom";
import emailjs from "@emailjs/browser";

const ClientForm = () => {
  const { referrer_id } = useParams();
  const [formData, setFormData] = useState({
    referrer_id: referrer_id,
    fullName: "",
    mobileNumber: "",
    email: "",
  });

  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const sendEmail = () => {
    emailjs.sendForm(
      "service_rt0zl19", // Your EmailJS service ID
      "template_dd5qq4s", // Your EmailJS template ID
      "#clientForm", // The ID of the form
      "jbiDn1z9uxbFC4PWC" // Your public key
    ).then(
      (result) => {
        console.log("SUCCESS!", result.text);
      },
      (error) => {
        console.log("FAILED...", error.text);
      }
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("formData", formData);
    try {
      const res = await axios.post(
        "http://localhost:8080/submitform",
        JSON.stringify(formData),
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      // console.log(res);
      setMessage(res.data.message);
      sendEmail();
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div className={styles.clientFormMain}>
      <form
        onSubmit={handleSubmit}
        id="clientForm"
        className={styles.clientFormStyles}
      >
        <FormControl fullWidth>
          <FormLabel htmlFor="referrerId">Referrer's ID</FormLabel>
          <TextField
            type="text"
            id="referrerId"
            name="referrer_id"
            size="small"
            color="secondary"
            value={formData.referrer_id}
            required
            disabled
          />
          <br />
          <FormLabel htmlFor="fullName">Full Name:</FormLabel>
          <TextField
            type="text"
            id="fullName"
            name="fullName"
            size="small"
            color="secondary"
            onChange={handleChange}
            value={formData.fullName}
            required
          />
          <br />
          <FormLabel htmlFor="mobileNumber">Mobile Number:</FormLabel>
          <TextField
            type="number"
            id="mobileNumber"
            name="mobileNumber"
            size="small"
            color="secondary"
            onChange={handleChange}
            value={formData.mobileNumber}
            required
          />
          <br />
          <FormLabel htmlFor="email">Email:</FormLabel>
          <TextField
            type="email"
            id="email"
            name="email"
            size="small"
            color="secondary"
            onChange={handleChange}
            value={formData.email}
            required
          />
          <br />
          <Button type="submit" id="submitBtn" color="secondary">
            Get A Call
          </Button>
        </FormControl>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default ClientForm;
