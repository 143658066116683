import React from "react";
import ResponsiveGrid from "../ResponsiveGrid/ResponsiveGrid";

import image1 from "../../gallery/sliding01.png"; // Adjust the paths to your image files
import image2 from "../../gallery/sliding02.png";
import image3 from "../../gallery/sliding03.png";
import image4 from "../../gallery/sliding04.png";
import image5 from "../../gallery/sliding05.png";

const cardName1 = "30 Days Plan";
const cardName2 = "90 Days Plan";
const cardName3 = "Healthy Diet Plan";
const cardName4 = "Healthy Breakfast Plan";
const cardName5 = "Meal Replacement Plan";
const cardName6 = "Cellular Nutrition Plan";
const cardName7 = "Complete Body Need Plan";

const text1 =
  "A proven strategy for adopting a better, healthy, active lifestyle way to develop new habits";
const text2 =
  "Body weight exercise and proper diet program to help you achieve your health goal";
const text3 =
  "A diet that maintains or improves overall health which provides essential nutrition such as macro-nutrients, micro-nutrients & fluids";
const text4 =
  "Breakfast that includes carbohydrates, proteins, healthy fats and fiber";
const text5 =
  "It is a drink, bar or soup which is a substitute for solid food with low calories and high nutritious value";
const text6 =
  "Our body is made up of cells and this is the process of nourishing these cells";
const text7 =
  "To become healthy our body needs six essential nutrients i.e. vitamins, minerals, protein, fats, water & carbohydrates ";

const plansData = [
  { img: image1, text: text1, cardName: cardName1 },
  { img: image2, text: text2, cardName: cardName2 },
  { img: image3, text: text3, cardName: cardName3 },
  { img: image4, text: text4, cardName: cardName4 },
  { img: image5, text: text5, cardName: cardName5 },
  { img: image1, text: text6, cardName: cardName6 },
  { img: image2, text: text7, cardName: cardName7 },
]; // Add more images as needed

const Plans = () => {
  return (
    <>
      <ResponsiveGrid data={plansData} />
    </>
  );
};

export default Plans;
