import React from "react";
import Classes from "../../components/Classes/Classes";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";

const ClassesPage = () => {
  return (
    <>
      <Navbar />
      <Classes />
      <Footer />
    </>
  );
};

export default ClassesPage;
