import React from "react";
import ClientForm from "../../components/ClientForm/ClientForm";
import Navbar from "../../components/Navbar/Navbar";

const ClientFormPage = () => {
  return (
    <>
      <ClientForm />
    </>
  );
};

export default ClientFormPage;
